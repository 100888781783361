<template>
  <div>
    <div class="d-flex align-items-center th-my-24">
      <v-divider></v-divider>
      <small
        class="caption th-px-8"
        v-html="$translation.t('Don\'t have an account?')"
      ></small>
      <v-divider></v-divider>
    </div>

    <v-btn
      color="primary"
      outlined
      large
      block
      class="th-mt-16"
      @click="createNewAccount"
    >
      {{ $translation.t("Create new account") }}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "CreateNewAccountButton",
  onRegister: {
    type: Function,
    default: null,
  },
  methods: {
    createNewAccount() {
      if (this.onRegister && typeof this.onRegister === "function") {
        this.onRegister();
        return;
      }

      this.$router.push({ name: "register", params: this.$route.params });
    },
  },
};
</script>

<style lang="scss" scoped></style>
