<template>
  <auth-card>
    <template v-slot:title>
      <span v-html="$translation.t('login.subtitle')"></span>
    </template>

    <template v-slot:footer>
      <create-new-account-button />
    </template>
  </auth-card>
</template>

<script>
import AuthCard from "./AuthCard";
import CreateNewAccountButton from "./CreateNewAccountButton";

export default {
  name: "Login",
  components: { CreateNewAccountButton, AuthCard },
};
</script>
